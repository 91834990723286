import React, { createContext, useContext, useEffect, useState } from "react";
import {
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useFirestore } from "reactfire";

const AuthContext = createContext({
  currentUser: null,
  signInWithGoogle: () => Promise,
  logout: () => Promise,
  isUserSignedIn: Boolean,
  toggleAuth: () => Promise,
  updateDocFields: () => Promise,
});

export const useAuth = () => useContext(AuthContext);

export default function ({ children, auth }) {
  const [currentUser, setCurrentUser] = useState(null);
  const firestore = useFirestore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user ? user : null);
    });
    return () => unsubscribe();
  }, [auth]);

  const logout = () => signOut(auth);

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider).catch((e) => console.error(e));
  };

  const isUserSignedIn = !!currentUser;
  const toggleAuth = () => (isUserSignedIn ? logout() : signInWithGoogle());

  const getUserFirebaseRef = async () => {
    let q = query(
      collection(firestore, "people"),
      where("email", "==", currentUser.email)
    );
    let querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      q = query(
        collection(firestore, "people"),
        where("name", "==", currentUser.displayName)
      );
      querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        console.log("not found ! %o is not in the database", currentUser);
        return null;
      }
    }

    return querySnapshot.docs[0].ref;
  };

  const updateDocFields = async (fields) => {
    const ref = await getUserFirebaseRef();
    if (!ref) return;
    return updateDoc(ref, {
      ...fields,
    })
      .then(() => true)
      .catch((e) => {
        // if user is not using seamless.se domain the write will fail due to rules in here
        // https://console.firebase.google.com/u/3/project/wherearewe-50cd1/firestore/rules
        console.log(e);
        console.log("Nice try hacker 🔪🔪🔪");
      });
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isUserSignedIn,
        signInWithGoogle,
        logout,
        toggleAuth,
        updateDocFields,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
