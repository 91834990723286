import { head } from "lodash/fp";
import React from "react";
import { Avatar } from "@mui/material";
import { Marker } from "react-map-gl";

const size = 40;

const style = {
  cursor: "pointer",
  fontSize: (size * 2) / 3,
  transform: `translate(${-size / 2}px,${-size}px)`,
};

const Pin = ({ person, onClick }) => (
  <Marker longitude={person.x} latitude={person.y}>
    <Avatar src={person.pictureUrl || null} style={style} onClick={onClick}>
      {!person.pictureUrl && head(person.name)}
    </Avatar>
  </Marker>
);

export default Pin;
