import { collection, query, where } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { useEffect, useState } from "react";

const usePeople = (currentUser) => {
  const [people, setPeople] = useState([]);
  const firestore = useFirestore();
  const peopleCollection = collection(firestore, "people");
  const peopleQuery = query(peopleCollection, where("toShow", "!=", false));
  const { status, data } = useFirestoreCollectionData(peopleQuery);

  useEffect(() => {
    if (data) {
      if (currentUser) {
        setPeople(data.filter(({ email }) => email !== currentUser.email));
        return;
      }

      setPeople(data);
    }
  }, [data, currentUser]);

  return {
    status,
    people,
  };
};
export default usePeople;
