import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AuthProvider from "./authContext";
import {
  FirebaseAppProvider,
  FirestoreProvider,
  useFirebaseApp,
} from "reactfire";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Home from "./pages/home";

const theme = createTheme({
  palette: {
    whiteSmoke: "#f3f3f3",
    lightGrey: "#f6f6f6",
  },
});

const firebaseConfig = {
  apiKey: "AIzaSyAjLetP3TkNV3rebv2K0mRKeMkuIpXow0U",
  authDomain: "wherearewe-50cd1.firebaseapp.com",
  projectId: "wherearewe-50cd1",
  storageBucket: "wherearewe-50cd1.appspot.com",
  messagingSenderId: "54698603835",
  appId: "1:54698603835:web:33c3fd8a67f461a32b331e",
};

const Layout = () => {
  const firebaseApp = useFirebaseApp();
  const firestoreInstance = getFirestore(firebaseApp);
  const auth = getAuth();

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <AuthProvider auth={auth}>
        <ThemeProvider theme={theme}>
          <Router>
            <Routes>
              <Route exact path="/" element={<Home />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </AuthProvider>
    </FirestoreProvider>
  );
};

const App = () => (
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <Layout />
  </FirebaseAppProvider>
);

export default App;
