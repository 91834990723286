import React, { useEffect, useState } from "react";
import ReactMapGL, { FlyToInterpolator } from "react-map-gl";
import Pin from "./components/Pin";

import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const baseView = {
  width: "100%",
  height: "100%",
  transitionDuration: 600,
  transitionInterpolator: new FlyToInterpolator(),
};

const defaultView = {
  latitude: 10,
  longitude: 16,
  zoom: 1.7,
};

const BEARING_RANGE = 12;
const PITCH_RANGE = 30;

export default ({ peopleList, me, focusOn = defaultView, onPersonClick }) => {
  const [viewport, setViewPort] = useState({
    ...baseView,
    ...defaultView,
  });

  const updateViewPort = (newViewPort) => setViewPort(newViewPort);

  useEffect(() => {
    setViewPort({
      ...baseView,
      bearing: !!focusOn
        ? Math.random() * BEARING_RANGE - BEARING_RANGE / 2
        : 0,
      pitch: !!focusOn ? Math.random() * PITCH_RANGE - PITCH_RANGE / 2 : 0,
      zoom: 10,
      ...focusOn,
    });
  }, [focusOn]);

  const onResetMap = () => {
    setViewPort({
      ...baseView,
      ...defaultView,
    });
  };

  return (
    <ReactMapGL
      {...viewport}
      onViewportChange={updateViewPort}
      onClick={onResetMap}
    >
      {[...peopleList, ...(me ? [me] : [])].map((person, index) => {
        return (
          <Pin person={person} key={index} onClick={onPersonClick(person)} />
        );
      })}
    </ReactMapGL>
  );
};
