import React from "react";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { withStyles } from "@mui/styles";
import {
  ListItemText,
  ListItem,
  Grid,
  Tooltip,
  IconButton,
  Avatar,
} from "@mui/material";
import { LocationOff, LocationOn } from "@mui/icons-material";
import { useAuth } from "../../../../../authContext";
import EditableTextField from "./EditableTextField";

const itemWidth = 350;

const styles = (theme) => ({
  personItem: {
    cursor: "pointer",
    paddingLeft: 30,
    width: itemWidth,
    "&:hover": {
      backgroundColor: theme.palette.whiteSmoke,
    },
  },
  connectedPersonItem: {
    backgroundColor: theme.palette.lightGrey,
  },
  avatar: {
    marginRight: 10,
  },
  input: {
    display: "none",
  },
  controlsContainer: {
    marginLeft: "auto",
  },
  locationsIcon: {
    fontSize: 15,
  },
});

const PersonItem = (props) => {
  const { classes, onPersonClick, person, index, isMe = false } = props;
  const { updateDocFields } = useAuth();
  const storage = getStorage();

  const onRoleUpdated = async (role) => {
    const isUpdated = await updateDocFields({ role });
    if (isUpdated) console.log("Role saved successfully !");
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleUpload = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (!file) {
      console.log("no file");
      return;
    }
    const storageRef = ref(storage, `/images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on("state_changed", console.log, console.error, () => {
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        updateDocFields({
          pictureUrl: url,
        });
      });
    });
  };

  const hideLocation = async () => {
    await updateDocFields({ toShow: false });
  };
  const showLocation = async () => {
    await updateDocFields({ toShow: true });
  };

  return (
    <ListItem
      className={`${classes.personItem} ${
        isMe ? classes.connectedPersonItem : ""
      }`}
      onClick={onPersonClick(person)}
    >
      <input
        disabled={!isMe}
        accept="image/*"
        className={classes.input}
        id={`icon-button-file-${index}`}
        type="file"
        onClick={stopPropagation}
        onChange={handleUpload}
      />

      <label htmlFor={`icon-button-file-${index}`}>
        <IconButton
          onClick={stopPropagation}
          aria-label="Upload picture"
          component="span"
          disableRipple
        >
          <Tooltip followCursor={true} title={isMe ? "Change picture" : ""}>
            <Avatar
              className={classes.avatar}
              src={person.pictureUrl || null}
            />
          </Tooltip>
        </IconButton>
      </label>

      <Grid>
        <ListItemText primary={person.name} />
        <EditableTextField
          disabled={!isMe}
          role={person.role}
          onRoleUpdated={onRoleUpdated}
        />
      </Grid>

      {isMe && (
        <Grid className={classes.controlsContainer}>
          <Tooltip
            title={person.toShow ? "Hide my location" : "Show my location"}
          >
            <IconButton
              onClick={(e) => {
                stopPropagation(e);
                person.toShow ? hideLocation() : showLocation();
              }}
            >
              {person.toShow ? (
                <LocationOn className={classes.locationsIcon} />
              ) : (
                <LocationOff className={classes.locationsIcon} />
              )}
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </ListItem>
  );
};

export default withStyles(styles)(PersonItem);
