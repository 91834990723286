import { flow, get, groupBy, map } from "lodash/fp";
import React from "react";
import { withStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import Flag from "react-flagkit";
import countryCodeLookup from "country-code-lookup";
import PersonItem from "./components/PersonItem";

const mapNoCap = map.convert({ cap: false });
const itemWidth = 350;

const styles = (theme) => ({
  countryItem: {
    width: itemWidth,
  },
  flag: {
    marginRight: 16,
    verticalAlign: "middle",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    overflowY: "scroll",
    width: "100%",
  },
});

const convertToCountryList = flow(
  groupBy("countryCode"),
  mapNoCap((peopleList, countryCode) => ({
    peopleList,
    countryCode,
    countryName: countryCode ? get("country", countryCodeLookup.byIso(countryCode)) : "Unknown",
  }))
);

const PeopleList = (props) => {
  const { classes, peopleList = [], onPersonClick, me } = props;
  const countryList = convertToCountryList(peopleList);

  return (
    <List className={classes.root}>
      {me && (
        <PersonItem
          onPersonClick={onPersonClick}
          person={me}
          key={`me`}
          index={`me`}
          isMe
        />
      )}
      {countryList.map((country) => [
        <ListSubheader
          className={classes.countryItem}
          key={country.countryCode}
        >
          <Flag
            className={classes.flag}
            country={country.countryCode}
            size={30}
          />
          {country.countryName}
        </ListSubheader>,
        ...country.peopleList.map((person, index) => [
          <Divider
            key={`${country.countryCode}-${index}-divider`}
            variant="inset"
          />,
          <PersonItem
            onPersonClick={onPersonClick}
            person={person}
            key={`${country.countryCode}-${index}`}
            index={`${country.countryCode}-${index}`}
          />,
        ]),
        <Divider key={`${country.countryCode}.divider`} />,
      ])}
    </List>
  );
};

export default withStyles(styles)(PeopleList);
