import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import Edit from "@mui/icons-material/Edit";
import InputAdornment from "@mui/material/InputAdornment";
import { ListItemText, TextField, IconButton } from "@mui/material";

const styles = () => ({
  textField: {
    color: "black",
    border: 0,
    width: 180,
  },

  disabled: {
    color: "black",
    borderBottom: 0,
    "&:before": {
      borderBottom: 0,
    },
  },
});

const EditableTextField = (props) => {
  const { classes, role: initialRole, disabled, onRoleUpdated } = props;
  const [role, setRole] = useState(initialRole);
  const [editMode, setEditMode] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const handleMouseOver = () => {
    if (!mouseOver) {
      setMouseOver(true);
    }
  };

  const handleMouseOut = () => {
    if (editMode) {
      setMouseOver(false);
      setEditMode(false);
      if (initialRole !== role && role !== "") onRoleUpdated(role);
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();
    setEditMode(true);
    setMouseOver(false);
  };

  if (disabled) return <ListItemText secondary={role} />;

  return (
    <TextField
      variant="standard"
      defaultValue={role}
      error={role === ""}
      onChange={handleChange}
      disabled={!editMode}
      className={classes.textField}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      onClick={(e) => e.stopPropagation()}
      InputProps={{
        classes: {
          disabled: classes.disabled,
          disableUnderline: true,
        },
        endAdornment: mouseOver ? (
          <InputAdornment position="end">
            <IconButton onClick={handleClick}>
              <Edit style={{ fontSize: 20 }} />
            </IconButton>
          </InputAdornment>
        ) : (
          ""
        ),
      }}
    />
  );
};

export default withStyles(styles)(EditableTextField);
