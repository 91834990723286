import { CircularProgress, Grid, Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useState, useEffect, useCallback } from "react";
import "mapbox-gl/src/css/mapbox-gl.css";
import "./Home.css";
import Map from "./components/Map/Map";
import PeopleList from "./components/PeopleList/PeopleList";
import { useAuth } from "../../authContext";
import { iso1A2Code } from "@ideditor/country-coder";
import usePeople from "./components/hooks/usePeople";
import useMe from "./components/hooks/useMe";

const style = () => ({
  listContainer: {
    overflowY: "scroll",
  },
  root: {
    height: "100vh",
    width: "100vw",
    position: "relative",
  },
  rootForSpinner: {
    height: "100vh",
    width: "100vw",
    alignItems: "center",
    justifyContent: "center",
  },
  locateMe: {
    position: "absolute",
    top: "20px",
    left: "20px",
    zIndex: 1,
  },
});
const Home = ({ classes }) => {
  const [focusOn, setFocusOn] = useState();
  const { isUserSignedIn, toggleAuth, currentUser, updateDocFields } =
    useAuth();

  const { status, people } = usePeople(currentUser);
  const { me } = useMe(currentUser);

  const onLocate = useCallback(
    async (pos) => {
      const isUpdated = await updateDocFields({
        email: currentUser.email,
        countryCode: iso1A2Code([pos.coords.longitude, pos.coords.latitude]),
        x: pos.coords.longitude,
        y: pos.coords.latitude,
      });

      if (isUpdated) console.log("Position updated successfully !");
    },
    [currentUser, updateDocFields]
  );

  useEffect(() => {
    if (isUserSignedIn) {
      if ("navigator" in window) {
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            onLocate(pos);
          },
          (err) => console.error(err)
        );
      }
    }
  }, [isUserSignedIn, onLocate]);

  const onPersonClick = (person) => () => {
    setFocusOn({
      latitude: person.y,
      longitude: person.x,
    });
  };

  return status === "success" ? (
    <Grid className={classes.root} container wrap="nowrap">
      <Grid item className={classes.locateMe}>
        <Button onClick={toggleAuth}>
          {isUserSignedIn ? "Log out" : "Locate Me"}
        </Button>
      </Grid>
      <Grid className={"mapContainer"} item xs>
        <Map
          peopleList={people}
          me={me}
          focusOn={focusOn}
          onPersonClick={onPersonClick}
        />
      </Grid>
      <Grid className={classes.listContainer} item>
        <PeopleList me={me} peopleList={people} onPersonClick={onPersonClick} />
      </Grid>
    </Grid>
  ) : (
    <Grid className={classes.rootForSpinner} container wrap="nowrap">
      <Grid item>
        <CircularProgress size={80} />
      </Grid>
    </Grid>
  );
};

export default withStyles(style)(Home);
