import { collection, query, where } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

const useMe = (currentUser) => {
  const firestore = useFirestore();
  const peopleCollection = collection(firestore, "people");
  const meQuery = query(
    peopleCollection,
    where("email", "==", currentUser ? currentUser.email : "undefined")
  );
  const { status, data } = useFirestoreCollectionData(meQuery);

  return {
    status,
    me: data && data.length === 1 ? data[0] : null,
  };
};
export default useMe;
